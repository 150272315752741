import { InvoiceSummary } from '../../../store/data/types'
import React, { useState } from 'react'
import {
  StyledOptionButton,
  StyledNfeLink,
  StyledWrapperButtons,
  StyledCollapseWrap,
  StyledNFPopover,
  StyledPopoverWrapper,
} from './styles/DesktopLinkBoard.style'

import { InvoiceCardLink } from './InvoiceCardLink'
import { Option } from './InvoiceCardLinkBoard'

export const DesktopLinkBoard = ({
  options,
  invoice,
}: {
  options: Option[]
  invoice: InvoiceSummary
}) => {
  const { shopperPlanId, nfeUrls } = invoice

  const [openPopover, setOpenPopover] = useState(false)
  const [modalAnchorEl, setModalAnchorEl] = useState<Element | null>(null)

  const handleClick = (event: any) => {
    setModalAnchorEl(event.currentTarget)
    setOpenPopover(!openPopover)
  }

  const handleClose = () => {
    setOpenPopover(false)
    setModalAnchorEl(null)
  }

  return (
    <StyledWrapperButtons>
      {options.map((option, index) =>
        option.subOptions.length <= 0 ? (
          <InvoiceCardLink key={index} option={option} />
        ) : (
          <StyledOptionButton
            key={option.label}
            data-shopper-plan={shopperPlanId}
            onClick={handleClick}
            aria-expanded={openPopover}
          >
            <StyledCollapseWrap id={shopperPlanId} disabled={nfeUrls.length <= 0}>
              Doc. Fiscal
              <StyledNFPopover
                marginThreshold={0}
                open={openPopover}
                onClose={handleClose}
                anchorEl={modalAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                disableEnforceFocus={true}
              >
                <StyledPopoverWrapper>
                  {nfeUrls.length > 0 &&
                    nfeUrls.map((url, i) => (
                      <StyledNfeLink key={url} href={url} target="_blank" rel="noreferrer">
                        {url.includes('amazon') ? `Abrir Nota Fiscal ${i + 1}` : 'Cupom Fiscal'}
                      </StyledNfeLink>
                    ))}
                </StyledPopoverWrapper>
              </StyledNFPopover>
            </StyledCollapseWrap>
          </StyledOptionButton>
        )
      )}
    </StyledWrapperButtons>
  )
}
