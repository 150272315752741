import { PaymentLabels } from '../components/organisms/Cart/interfaces'
import { MerchantStatus } from '../store/slices/session'

export const SSA_USERS_WITH_BOTH_INVOICE_TYPES = 'SSAUsersWithBothInvoiceTypes'

export const INVOICE_PAGE_SIZE = 15

export const SECOND_MS = 1000
export const MINUTE_MS = SECOND_MS * 60

export const CX_WHATSAPP_NUMBER = '552136093614' //TODO: move this to an enviroment variable.

export const CLUBBI_HELP_LINK = 'https://clubbihelp.zendesk.com/hc/pt-br'

export const ZENDESK_HELP_LINK = `https://clubbihelp.zendesk.com/hc/pt-br`

export const ZENDESK_PROFILE_EDIT_FAQ_LINK =
  'https://clubbihelp.zendesk.com/hc/pt-br/articles/23963343513364-Como-alterar-meus-dados'

export const SIGN_UP_LINK = `https://api.whatsapp.com/send/?phone=${CX_WHATSAPP_NUMBER}&text=Ol%C3%A1%21+Quero+fazer+meu+cadastro+Clubbi%21&type=phone_number&app_absent=0`

export const whatsappHelpLinkGenerator = (merchantCode = '', shopperPlanId: string) =>
  `https://api.whatsapp.com/send?phone=${CX_WHATSAPP_NUMBER}&text=Ol%C3%A1!%20Sou%20o%20cliente%20${merchantCode}%20e%20preciso%20de%20ajuda%20com%20o%20pedido%20da%20entrega%20%23${shopperPlanId}.`

export const whatsappBillingCXLinkGenerator = (merchantCode = '', shopperPlanId: string) =>
  `https://api.whatsapp.com/send?phone=${CX_WHATSAPP_NUMBER}&text=Ol%C3%A1!%20Sou%20o%20cliente%20${merchantCode}%20e+gostaria+de+enviar+o+comprovante+de+pagamento+do+pedido+vinculado+%C3%A0+entrega%20%23${shopperPlanId}.`

export const whatsappHelpCXLinkGenerator = (merchantCode = '') =>
  `https://api.whatsapp.com/send/?phone=${CX_WHATSAPP_NUMBER}&text=Olá.%20Fiz%20um%20pedido%20e%20está%20em%20análise.%20Meu%20código%20é%20${merchantCode}%20e%20tenho%20uma%20dúvida.&type=phone_number&app_absent=0.`

export const whatsappOutsourcedNfCXLinkGenerator = (merchantCode = '', shopperPlanId: string) =>
  `https://api.whatsapp.com/send?phone=${CX_WHATSAPP_NUMBER}&text=Ol%C3%A1!%20Sou%20o%20cliente%20${merchantCode}%20e+gostaria+de+solicitar+a+nota+fiscal+para+produtos+parceiros+do+pedido+vinculado+%C3%A0+entrega%20%23${shopperPlanId}.`

export const instantPaymentData: PaymentLabels[] = [{ id: 2, label: 'Pagamento à vista via Pix' }]

export const paymentTypesData: PaymentLabels[] = [
  { id: 0, label: 'Boleto' },
  { id: 1, label: 'Cartão de crédito' },
  { id: 2, label: 'Pix' },
]

export const invoiceTypesData: PaymentLabels[] = [
  { id: 2, label: 'Nota Fiscal - DANFE', subLabel: 'Com CNPJ na nota' },
  { id: 0, label: 'Cupom Fiscal', subLabel: 'Sem CNPJ na nota' },
]

export const orderSuccessfulSteps: string[] = ['Pedido aprovado', 'Coleta', 'Entrega', 'Pagamento']

export const orderUnderReviewSteps: string[] = [
  'Análise de crédito',
  'Pedido aprovado',
  'Coleta',
  'Entrega',
  'Pagamento',
]

export const pendingOrderMessage =
  'Obrigado pela preferência! Você receberá os produtos no seu estabelecimento. \nAtualizações do pedido serão realizadas via WhatsApp.'

export const creditReviewOrderMessage =
  'Seu pedido excedeu o limite atualmente disponível e ainda não foi finalizado. No entanto, não se preocupe, nós iremos avaliá-lo e entraremos em contato dentro de 1 (um) dia útil para confirmar e informar a nova data de entrega. Caso seja aprovado, os detalhes do pedido poderão ser visualizados acessando a aba de '

export const creditReviewLinkTxt = 'Saiba mais sobre a análise.'

export const creditReviewOrderTitleText = 'Seu pedido está sob análise de crédito'
export const confirmedOrderTitleText = 'Recebemos o seu pedido!'

export const ZENDESK_ORDERS_FAQ_LINK =
  'https://clubbihelp.zendesk.com/hc/pt-br/sections/9390877575060-Pedidos'

export const urlImages =
  'https://res.cloudinary.com/dti85ldyv/image/upload/c_scale,h_120/f_png/c_limit,d_product-thumb_ybwmng.png/category'

export const blockingStatuses = [
  MerchantStatus.DEACTIVATED,
  MerchantStatus.DEFAULTED,
  MerchantStatus.NEGATIVATED,
]

export const GOOD_GRADES = [
  'Preço dos produtos',
  'Tempo de entrega',
  'Praticidade',
  'Variedade dos produtos',
  'Não ter pedido mínimo',
  'Prazo de pagamento',
  'Suporte',
  'Valor da taxa de entrega',
]

export const BAD_GRADES = [
  'Preço dos produtos',
  'Produtos danificados',
  'Navegação e solicitação de pedidos',
  'Validade dos produtos',
  'Maior variedade de produtos',
  'Suporte',
  'Tempo de entrega',
  'Faltas de produto',
  'Produtos errados',
  'Valor da taxa de entrega',
]

export const NPS_GRADES = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']

export const WHATSAPP_API_URL =
  'https://api.whatsapp.com/send/?text=Cansado+de+ir+sempre+aos+atacados%3F+A+Clubbi+entrega+tudo+que+precisa+na+sua+loja+e+ainda+te+ajuda+a+economizar.+Compre+com+os+melhores+pre%C3%A7os+de+cada+rede%2C+sem+pedido+m%C3%ADnimo+e+com+v%C3%A1rias+formas+de+pagamento.+Com+a+minha+indica%C3%A7%C3%A3o+voc%C3%AA+ganha+R%2430+na+primeira+compra%21+Se+inscreva+em+clubbi.com.br+para+eles+entrarem+em+contato%21&app_absent=0'

export const CUSTOM_PRODUCTS_QUANTITY = 20

export const SEARCH_RESULTS_QUANTITY = 4

export const CUSTOM_PRODUCTS_QUANTITY_PER_PAGE = 50

export const TAX_BENEFIT_MESSAGE =
  'Este produto tem sua base de cálculo do ICMS reduzida em 41.176%, conforme Decreto Estadual Nº 7.799/00'

export const TAX_BENEFIT_LINK =
  'https://clubbihelp.zendesk.com/hc/pt-br/articles/19191142358804-Tributa%C3%A7%C3%A3o-Fiscal-na-Bahia'

export const DELAY_TO_SHOW_CARD = 1000

export const DELAY_TO_HIDE_CARD = 5000

export const infoNavigationSteps = [
  {
    title: 'Conheça a nova forma de navegar no site da Clubbi!',
    description: 'Começando, aqui é onde você encontra Ofertas e Categorias.',
  },
  {
    title: 'Busque muito mais rápido!',
    description: 'Experimente a nova área de busca, mais rápida e muito mais eficiente.',
  },
  {
    title: 'Lista de Compras e Perfil',
    description:
      'Nesta área acesse seu perfil e faça a busca rápida por vários produtos ao mesmo tempo.',
  },
  {
    title: 'Missões e Carrinho',
    description: 'Por último, aqui ficou o acesso direto a Missões e ao seu Carrinho de Compras.',
  },
]

export const infoNavigationStepsDesktop = [
  {
    title: 'Conheça a nova forma de navegar no site da Clubbi!',
    description: 'Começando, aqui é onde você encontra Ofertas e Categorias.',
  },
  {
    title: 'Busque muito mais rápido!',
    description: 'Experimente a nova área de busca, mais rápida e muito mais eficiente.',
  },
  {
    title: 'Lista de Compras',
    description:
      'Faça buscas rápidas por diversos produtos ao mesmo tempo. Basta copiar e colar sua lista de compras.',
  },
  {
    title: 'Missões e Carrinho',
    description: 'Por último, aqui ficou o acesso direto a Missões e ao seu Carrinho de Compras.',
  },
]

export const categoriesTags = [
  'Mercearia',
  'Padaria e Matinais',
  'Biscoitos, Salgadinhos e Doces',
  'Laticínios',
  'Bebidas Alcoólicas',
  'Bebidas Não Alcoólicas',
  'Limpeza',
  'Higiene e Beleza',
  'Bazar e Utilidades',
  'Bazar em Geral',
  'Hortifruti e Ovos',
]

export const HITS_PER_PAGE = 5

export const ALGOLIA_RESULTS = 1

export const BEACON_CONVERSION_URL =
  'https://events.newtail-media.newtail.com.br/v1/beacon/conversion'

export const WARNING_CUTOFF_START_TIME = 20

export const WARNING_CUTOFF_END_TIME = 6

export const instantPaymentMessage =
  'No momento da entrega, uma pessoa responsável deve estar disponível para efetuar o pagamento.'

export const noDeadlineAvailableMessage = 'Informação de prazo de pagamento indisponível'

export enum DELIVERY_TYPE_ENUM {
  fast = 'fast',
  free = 'free',
  blackFriday = 'blackFriday',
}

export const DELIVERY_TYPES = {
  fast: { value: 15, daysToDelivery: 1 },
  free: { value: 0, daysToDelivery: 2 },
  blackFriday: { value: 15, daysToDelivery: 2 },
}

export const MERCHANT_CODE_CHARACTERS = 5

export const WITHOUT_PRICE = '--'

export const REFRESH_TOKEN_INTERVAL = 3600000

export const PRICING_GROUP_IDS_SALVADOR = [16, 75]

export const PRICING_GROUP_IDS_IN_GET_MORE_FOR_LESS = [
  70, 71, 72, 73, 74, 76, 78, 81, 82, 83, 85, 86, 87, 88,
]

export const EBD_SUPPLIER = 'EBD'
export const CLUBBI_SUPPLIER = 'clubbi'

export const BLOCKED_EDITION_SUPPLIERS = [EBD_SUPPLIER]

export const TWO_DAYS_STORE = 'EBD'

export const DIFFERENT_MODE_ERROR = 6
