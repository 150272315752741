import { Product } from '../../../../store/data/types'
import { Warnings } from '../interfaces'
import { ProductPriceChangeItem } from './ProductPriceChangeItem'
import { QuantityAndModeChangeItem } from './QuantityAndModeChangeItem'
import { StyledListGroup, StyledListGroupItem, StyledModal } from './styles/CartItemsChanges.style'

export type WarningNames =
  | 'outOfStock'
  | 'priceChanges'
  | 'limitChanges'
  | 'quantityChanges'
  | 'modeChanges'

export const CartItemsChanges = ({
  warningName,
  warnings,
}: {
  warningName: WarningNames
  warnings: Warnings
}) => {
  const modeChangesFeedback =
    'Os seguintes produtos da sua cesta não estão disponíveis para esta modalidade de compra: '
  const removeFeedback = `O item a seguir consta em falta em nosso estoque: `
  const priceChangesFeedback = 'Os seguintes produtos da sua cesta mudaram de preço: '
  const limitChangesFeedback = 'Os seguintes produtos da sua cesta mudaram de quantidade: '
  const quantityAndModeChangesFeedback = 'Os seguintes produtos da sua cesta mudaram de quantidade:'

  const isOutOfStock = warningName === 'outOfStock'
  const isPriceChange = warningName === 'priceChanges'
  const isLimitChange = warningName === 'limitChanges'
  const isQuantityChanges = warningName === 'quantityChanges'
  const isModeChange = warningName === 'modeChanges'

  return (
    <StyledModal>
      <StyledListGroup>
        <span>
          {isModeChange && modeChangesFeedback}
          {isOutOfStock && removeFeedback}
          {isPriceChange && priceChangesFeedback}
          {isLimitChange && limitChangesFeedback}
          {isQuantityChanges && quantityAndModeChangesFeedback}
        </span>
        <br />
      </StyledListGroup>
      {isModeChange && (
        <>
          {warnings[warningName].map(({ cartItem, product }) => (
            <StyledListGroupItem key={product.ean}>
              <p>{product.description}</p>
              <p>Modalidade indisponível: {cartItem.isPackageMode ? 'Caixa' : 'Fracionado'}</p>
            </StyledListGroupItem>
          ))}
          <p>
            Removemos o item da sua cesta, mas{' '}
            <b>você pode tentar adicionar novamente na modalidade de compra disponível.</b>
          </p>
        </>
      )}
      {isPriceChange && (
        <StyledListGroupItem>
          {warnings[warningName].map(({ cartItem, product }) => (
            <ProductPriceChangeItem
              cartItem={cartItem}
              updatedProduct={product}
              key={product.ean}
            />
          ))}
        </StyledListGroupItem>
      )}
      {isLimitChange &&
        warnings[warningName].map(({ cartItem, product }) => (
          <StyledListGroupItem key={product.ean}>
            <p key={product.ean}>{product.description}</p>
          </StyledListGroupItem>
        ))}
      {isOutOfStock && (
        <>
          {warnings[warningName].map((updatedProduct: Product) => (
            <StyledListGroupItem key={updatedProduct.ean}>
              <p key={updatedProduct.ean}>{updatedProduct.description}</p>
            </StyledListGroupItem>
          ))}
          <p>
            Removemos o item da sua cesta, mas{' '}
            <b>você pode tentar adicionar novamente em uma quantidade menor.</b>
          </p>
        </>
      )}
      {isQuantityChanges && (
        <>
          {warnings[warningName]?.map((quantityChange, index) => {
            return <QuantityAndModeChangeItem quantityChange={quantityChange} key={index} />
          })}
        </>
      )}
    </StyledModal>
  )
}
