import React, { useState } from 'react'
import { ArrowBackIcon, Menu } from 'clubbi-ui'
import {
  StyledMobileInvoiceCardLinkBoard,
  StyledIconButton,
  StyledMenuItem,
} from './styles/MobileLinkBoard.style'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Option } from './InvoiceCardLinkBoard'
import { InvoiceSummary } from '../../../store/data/types'

export const MobileLinkBoard = ({
  options,
  invoice,
}: {
  options: Option[]
  invoice: InvoiceSummary
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [subOptions, setSubOptions] = useState<Option[]>([])

  const handleItemClick = (option: Option) => {
    if (option.subOptions?.length > 0) {
      setSubOptions(option.subOptions)
    } else {
      setAnchorEl(null)
      window.open(option.url, '_blank')
    }
  }

  const handleBackClick = () => {
    setSubOptions([])
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setSubOptions([])
  }

  return (
    <StyledMobileInvoiceCardLinkBoard>
      <StyledIconButton onClick={handleOpen}>
        <MoreVertIcon style={{ fontSize: '12px' }} />
        Mais detalhes
      </StyledIconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {subOptions.length > 0 && (
          <StyledMenuItem disabled={false} onClick={handleBackClick}>
            <ArrowBackIcon size="12px" />
          </StyledMenuItem>
        )}
        {subOptions.length === 0
          ? options.map((option, index) => (
              <StyledMenuItem
                key={index}
                disabled={option.disabled}
                onClick={
                  option.disabled
                    ? () => {
                        null
                      }
                    : () => handleItemClick(option)
                }
              >
                {option.label}
              </StyledMenuItem>
            ))
          : subOptions.map((option, index) => (
              <StyledMenuItem
                key={index}
                disabled={option.disabled}
                onClick={() => handleItemClick(option)}
              >
                {option.label}
              </StyledMenuItem>
            ))}
      </Menu>
    </StyledMobileInvoiceCardLinkBoard>
  )
}
